import { Menu } from "../model/menu.model"
import { FittingkerMenuItems } from "./fittingker.menu";

  export var MESMenuItems: Menu[] = [
    {
      headTitle1: "MES",
    },
    // {
    //   title: 'Test API',
    //   path: "/test-api",
    //   icon: 'file',
    //   type: 'link',
    // },
    {
      title: "Orders",
      type: 'link',
      badgeType: "light-success",
      badgeValue: "New",
      icon: "task",
      active: false,
      path: "/order/list",
    },
    {
      title: "Projetcs",
      type: 'link',
      badgeType: "light-secondary",
      badgeValue: "In process",
      icon: "project",
      active: false,
      path: "/projects/list",
    },
    {
      title: "Resources",
      icon: "social",
      badgeType: "light-secondary",
      badgeValue: "In process",
      type: "sub",
      active: false,
      children: [
        { title: "Operators", type: "sub",  children: [ 
          {path: "/operators/list", title: "Operator list", type: "link"},
          { path: "/operators/skills", title: "Skills", type: "link" },
        ]},
        { path: "/operators/list", title: "Operators", type: "link" },
        { path: "/resources/machines", title: "Machines", type: "link" },
        { path: "/resources/machines/locations", title: "Machine Locations", type: "link" },
        { path: "/resources/andons", title: "Andons", type: "link" },
        { path: "/resources/tools", title: "Tools", type: "link" },
        { path: "plans/tasks", title: "Tasks", type: "link" },
        { path: "/machines", title: "Machines", type: "link" },
        { path: "/machine-locations", title: "Machine Locations", type: "link" },
        { path: "/fs/resource-tools", title: "Tools", type: "link" },
        { path: "/fs/resource-tools/scan", title: "Tool Scan", type: "link" },
      ],
    },
    {
      title: "Production",
      icon: "task",
      badgeType: "light-secondary",
      badgeValue: "In process",
      type: "sub",
      active: false,
      children: [
        { path: "b", title: "Production Lines", type: "link" },
        { path: "b", title: "Production Units", type: "link" },
        { path: "b", title: "Sensors ", type: "link" },
        { path: "b", title: "Data acquisition", type: "link" },
      ],
    },
    {
      title: "Maintanance",
      icon: "faq",
      badgeType: "light-secondary",
      badgeValue: "In process",
      type: "sub",
      active: false,
      children: [
        {
          title: "Tasks",
          icon: "user",
          type: "sub",
          active: false,
        },
        { path: "b", title: "Events", type: "link" },
      ],
    },
    {
      title: "Andon System",
      icon: "widget",
      // badgeType: "light-secondary",
      // badgeValue: "In process",
      type: "sub",
      active: false,
      children: [
            { path: "/andon/list", title: "Andon Lights", type: "link" },
            { path: "/andon/tables", title: "Andon Tables", type: "link" },
          ],
        },
        {
          title: "Shedule",
          icon: "calender",
          badgeType: "light-secondary",
          badgeValue: "In process",
      type: "sub",
      active: false,
      children: [
        { path: "/plans/detail", title: "Master Plan", type: "link" },
        { path: "b", title: "Fact Plan", type: "link" },
        { path: "/plans/candidates", title: "Candidate Plans", type: "link" },
        { path: "/plans/new", title: "Scheduler", type: "link" },
        { path: "/plans/store", title: "Plans", type: "link" },
        { path: "/plans/tasks", title: "Production Tasks", type: "link" },
      ],
    },
    {
      title: 'AnyLogic',
      path: "/anylogic",
      icon: 'file',
      type: 'link',
    },
    {
      title: "Quality",
      icon: "job-search",
      badgeType: "light-secondary",
      badgeValue: "In process",
      type: "sub",
      active: false,
      children: [
        { path: "b", title: "Inspection Plans", type: "link" },
        { path: "b", title: "Quality Checks", type: "link" },
        { path: "b", title: "Non-Conformance", type: "link" },
      ],
    },
    {
      title: "Statistics",
      icon: "charts",
      badgeType: "light-secondary",
      badgeValue: "In process",
      type: "sub",
      active: false,
      children: [
        { path: "chart/apex", title: "Operation", type: "link" },
        { path: "chart/chartjs", title: "Products", type: "link" },
        { path: "chart/chartist", title: "Maintanance", type: "link" },
      ],
    },
    {
      title: "Admin Center",
      icon: "social",
      badgeType: "light-secondary",
      badgeValue: "In process",
      type: "sub",
      active: false,
      children: [
        { path: "users", title: "Users", type: "link" },
        { path: "audit/list", title: "Audit Logs", type: "link" },
        // { path: "b", title: "Settings", type: "link" },
        // { path: "a", title: "Migrations", type: "link" },
        { path: "backup/list", title: "Backups", type: "link" },
        { path: "health", title: "Health", type: "link" },
      ],
    },
    {
      title: "Help",
      icon: "support-tickets",
      badgeType: "light-success",
      badgeValue: "New",
      type: "sub",
      active: false,
      children: [
        // {
        //   title: 'Documentation',
        //   path: "/support",
        //   badgeType: "light-secondary",
        //   badgeValue: "In process",
        //   icon: 'support-tickets',
        //   type: 'link',
        // },
        {
          title: 'Support',
          path: "/support",
          badgeType: "light-secondary",
          badgeValue: "In process",
          icon: 'support-tickets',
          type: 'link',
        },
        {
          title: 'Impressum',
          path: "/impressum",
          badgeType: "light-secondary",
          badgeValue: "In process",
          icon: 'faq',
          type: 'link',
        },
      ],
    },
  ];