import { Menu } from "../model/menu.model"


  export var NadudvariMenuItems: Menu[] = [
    {
      headTitle1: "Nádudvari",
    },
    {
      title: "Dashboards",
      icon: "home",
      type: "link",
      path: "/nadudvari-dashboard",
      active: true,
    },
    {
        title: "Products",
        type: 'link',
        icon: "ecommerce",
        path: "/nadudvari-product/list",
        active: false,
    },
    {
      title: "menu.productionPlanning",    //Készlet és igények
      icon: "widget",
      // badgeType: "light-success",
      // badgeValue: "New",
      active: false,
      type: "sub",
      children: [
        { path: "/demands/matrix", title: 'menu.demands', type: 'link', bookmark: true },
        { path: "https://nadudvari.epicinnolabs.hu:84", title: "Ütemezés", type: "extLink" },
        { path: "/plans/list", title: "Jelölt tervek", type: "link" },
        { path: "/plans/productionPlan", title: "Gyártási terv", type: "link" },
      ]
    },
    {
      title: 'menu.resources',
        type: "sub",
      active: false,
      icon: "social",
      children: [
        { path: "/resources/machines", title: 'menu.resources', type: 'link', bookmark: true },
        { path: "/machine-locations", title: 'Helységek', type: 'link', bookmark: true },
        // { path: "https://nadudvari.epicinnolabs.hu:81/nadudvari", title: 'Andon', type: 'extLink', bookmark: true },
        { path: "nadudvari-machine-data/production", title: 'menu.nadudvar.production', type: 'link', bookmark: true },
        { path: "nadudvari-machine-data/control-changes", title: 'menu.nadudvar.control_changes', type: 'link', bookmark: true },
        { path: "nadudvari-machine-data/errors", title: 'menu.nadudvar.errors', type: "link", bookmark: true }
      ]
    },
    {
      title: "Andon",
      type: 'extLink',
      badgeType: "light-success",
      badgeValue: "Új",
      icon: "social",
      path: "https://nadudvari.epicinnolabs.hu:81",
      active: false,
  },
    //  {
    //    title: "Production Plans",
    //    icon: "task",
    //    badgeType: "light-secondary",
    //    badgeValue: "In process",
    //    active: false,
    //    type: "sub",
    //    children: [
    //       { path: "https://nadudvari.epicinnolabs.hu:83", title: "Termelés ütemezés", type: "extLink" },
    //       { path: "/plans/list", title: "Candidate Plans", type: "link" },
    //       { path: "/plans/productionPlan", title: "Production Plan", type: "link" },
    //    ],
    // },
  //   {
  //     title: 'AnyLogic',
  //     path: "/anylogic",
  //     icon: 'file',
  //     type: 'link',
  //   },
  //   {
  //     title: "Quality",
  //     icon: "job-search",
  //     badgeType: "light-secondary",
  //     badgeValue: "In process",
  //     type: "sub",
  //     active: false,
  //     children: [
  //       { path: "b", title: "Inspection Plans", type: "link" },
  //       { path: "b", title: "Quality Checks", type: "link" },
  //       { path: "b", title: "Non-Conformance", type: "link" },
  //     ],
  //   },
    {
      title: "menu.statistics",
      icon: "charts",
      badgeType: "light-secondary",
      badgeValue: "Folyamatban",
      type: "link",
      active: false,
      path: "/nadudvari-statistics",
      // children: [
      //   // { path: "chart/apex", title: "Operation", type: "link" },
      //   { path: "chart/chartjs", title: "Opciók", type: "link" },
      //   // { path: "chart/chartist", title: "Maintanance", type: "link" },
      // ],
    },
    {
      title: "menu.admin_center",
      icon: "social",
      //badgeType: "light-secondary",
      //badgeValue: "In process",
      type: "sub",
      active: false,
      children: [
        { path: "users", title: "Users", type: "link" },
        { path: "audit/list", title: "Audit Logs", type: "link" },
        // { path: "b", title: "Settings", type: "link" },
        // { path: "a", title: "Migrations", type: "link" },
        { path: "backup/list", title: "Backups", type: "link" },
        { path: "health", title: "Health", type: "link" },
      ],
    },
    {
      title: "menu.help",
      icon: "support-tickets",
      type: "sub",
      active: false,
      children: [
        { title: 'menu.support', path: "/support", icon: 'support-tickets', type: 'link'},
        { title: 'menu.impressum', path: "/impressum", icon: 'faq', type: 'link'},
      ],
    },
  ];