import { Component, OnInit, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { NavService } from "../../services/nav.service";
import { LayoutService } from "../../services/layout.service";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Home } from "../../../shared/model/home.model"
import { HomeService } from "../../../shared/services/home.service"
import { environmentUrls } from '../../../../environments/environment';
import { TranslateService } from "@ngx-translate/core";

SwiperCore.use([Navigation, Pagination, Autoplay]);
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public elem: any;
  isImageLoading: boolean = false;
  home: Home;
  imageUrl: string;
  isLanguageSelectable: boolean = false;

  constructor(public layout: LayoutService, public navServices: NavService, public homeService: HomeService, public translateService: TranslateService, @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
    this.elem = document.documentElement;
    this.getHome();
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    if ((this.layout.config.settings.layout_version = "dark-only")) {
      document.body.classList.toggle("dark-only");
    }
    document.body.remove;
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  getHome() {
    this.homeService.getSelected().subscribe(
      (value: Home) => {
        this.home = value;
        this.home.logoLightURL = environmentUrls.documentPublic + this.home.logoLightURL;
        this.home.logoDarkURL = environmentUrls.documentPublic + this.home.logoDarkURL;
        this.home.logoSmallURL = environmentUrls.documentPublic + this.home.logoSmallURL;
        this.isImageLoading = true;
        this.isLanguageSelectable = this.home.showLanguageSelector;
        if (this.home != null && this.home.defaultLanguage != null && this.home.defaultLanguage != "") {
          this.changeLanguage(this.languages.find(x => x.code == this.home.defaultLanguage));
        } else {
          this.changeLanguage("hu");
        }
      });

  }

  changeLanguage(lang) {
    this.translateService.use(lang.code)
    this.selectedLanguage = lang;
  }

  public language: boolean = false;

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  },
  {
    language: 'Italian',
    code: 'it',
    type: 'IT',
    icon: 'it'
  },
  {
    language: 'Hungarian',
    code: 'hu',
    type: 'HU',
    icon: 'hu'
  },
  {
    language: 'Español',
    code: 'es',
    type: 'ES',
    icon: 'es'
  },
  {
    language: 'Français',
    code: 'fr',
    type: 'FR',
    icon: 'fr'
  },
  {
    language: 'Português',
    code: 'pt',
    type: 'BR',
    icon: 'pt'
  },
  {
    language: 'German',
    code: 'de',
    type: 'DE',
    icon: 'de'
  }
  ]

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'us'
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
}
