import { Menu } from "../model/menu.model"

export var BarcodeMenuItems: Menu[] = [
  {
    headTitle1: "Barcode Project",
  },
  {
    title: "Dashboards",
    icon: "home",
    type: "link",
    path: "/barcode-dashboard",
    active: false,
  },
  {
    title: "Products",
    type: 'link',
    icon: "product",
    active: false,
    path: "/barcoded-products/list",
  },

  {
    title: "menu.adminCenter",
    icon: "user",
    type: "sub",
    active: false,
    children: [
      { path: "users", title: "menu.users", type: "link" },
      { path: "audit/list", title: "menu.auditLogs", type: "link" },
      // { path: "b", title: "menu.settings", type: "link" },
      // { path: "a", title: "menu.migrations", type: "link" },
      { path: "backup/list", title: "menu.backups", type: "link" },
      { path: "health", title: "menu.health", type: "link" },
    ],
  },
  {
    title: "menu.help",
    icon: "support-tickets",
    type: "sub",
    active: false,
    children: [
      {
        title: 'menu.support',
        path: "/support",
        icon: 'support-tickets',
        type: 'link',
      },
      // {
      //   title: 'menu.impressum',
      //   path: "/impressum",
      //   icon: 'faq',
      //   type: 'link',
      // },
    ],
  },
];